<template>
    <form class="login" @submit.prevent="submit">
        <table>
            <tr>
                <td>
                    <label for="login">Login</label>
                </td>
                <td class="input">
                    <input name="login" ref="login">
                </td>
            </tr>
            <tr>
                <td>
                    <label for="password">Password</label>
                </td>
                <td class="input">
                    <input name="password" type="password" ref="password">
                </td>
            </tr>
            <tr>
                <td></td>
                <td class="submit">
                    <input type="submit">
                </td>
            </tr>
            <tr>
                <td></td>
                <td class="error input">
                    {{ error }}
                </td>
            </tr>
        </table>
    </form>
</template>

<script>
import gql from 'graphql-tag'

export default {
    name: 'LoginView',
    components: {},
    data() {
        return {
            error: ""
        }
    },
    mounted() {
        this.$refs.login.focus()
    },
    methods: {
        submit() {
            this.$apollo.mutate({
                mutation: gql`
                mutation($login: String!, $password: String!) {
                  user {
                    login(login: $login, password: $password) {
                      access_token
                      refresh_token
                      user {
                        id
                      }
                    }
                  }
                }`,
                variables: {
                    login: this.$refs.login.value,
                    password: this.$refs.password.value
                }
            }).then(({data: {user: {login: {access_token, refresh_token, user: {id}}}}}) => {
                this.$store.dispatch('login', {access_token, refresh_token, user_id: id});
                this.$router.push('/rooms')
            }).catch((error) => {
                this.error = error
            })
        }
    }
}
</script>

<style scoped>
.login {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login td {
    text-align: right;
}

.login .input input {
    width: 20em;
}

.login .error {
    text-align: center;
    color: red;
    max-width: 15em;
}
</style>
