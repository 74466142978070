<template>
    <div class="home">

    </div>
</template>

<script>

export default {
    name: 'HomeView',
    components: {}
}
</script>
