import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        authenticated: false,
        init: false,
        call: false,
        access_token: null,
        refresh_token: null,
        user_id: null,
        nickname: localStorage.getItem("nickname") || null
    },
    getters: {},
    mutations: {
        init(state, {call}) {
            state.init = true
            state.call = call
        },
        authentication(state, {authenticated}) {
            state.authenticated = authenticated
        },
        login(state, {access_token, refresh_token, user_id}) {
            state.authenticated = user_id !== ""
            state.access_token = access_token
            state.refresh_token = refresh_token
            state.user_id = user_id
            console.log(566, refresh_token)
            localStorage.setItem("access_token", access_token || "")
            localStorage.setItem("refresh_token", refresh_token || "")
            localStorage.setItem("user_id", user_id || "")
        },
        nickname(state, {nickname}) {
            state.nickname = nickname
            if (!state.authenticated && nickname) {
                localStorage.setItem("nickname", nickname || '')
            }
        }
    },
    actions: {
        init({commit}, {call}) {
            commit('init', {call})
        },
        login({commit}, {access_token, refresh_token, user_id, nickname}) {
            commit('login', {access_token, refresh_token, user_id})
            commit('nickname', {nickname})
        },
        nickname({commit}, {nickname}) {
            commit('nickname', {nickname})
        }
    },
    modules: {}
})
