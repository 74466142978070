<template>
    <div id="app">
        <NavigationLinks/>
        <router-view/>
    </div>
</template>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}
</style>
<script>
import NavigationLinks from "@/components/NavigationLinks";
import {call} from './vue-apollo'
import gql from "graphql-tag";

export default {
    components: {NavigationLinks},
    beforeCreate() {
        const user_id = localStorage.getItem("user_id")
        const access_token = localStorage.getItem("access_token")
        const refresh_token = localStorage.getItem("refresh_token")

        this.$store.dispatch('login', {access_token, refresh_token, user_id});

        if (!call && refresh_token) {
            this.$apollo.mutate({
                mutation: gql`mutation($refresh_token: String!) {
          user {
            refresh(refresh_token: $refresh_token) {
              access_token
              refresh_token
              user {
                id
                nickname
              }
            }
          }
        }`,
                variables: {
                    refresh_token
                }
            }).then(({data: {user: {refresh: {access_token, refresh_token, user: {id, nickname}}}}}) => {
                this.$store.dispatch('login', {access_token, refresh_token, user_id: id, nickname});
                this.$store.dispatch('init', {call: false});
            }).catch(() => {
                this.$store.dispatch('login', {access_token: '', refresh_token: '', user_id: '', nickname: ''});
                this.$store.dispatch('init', {call: false});
            })
        } else if (call) {
            this.$apollo.mutate({
                mutation: gql`query {
          user {
            info {
              id
              nickname
            }
          }
        }`,
                variables: {}
            }).then(({data: {user: {info: {nickname}}}}) => {
                this.$store.dispatch('nickname', {nickname});
                this.$store.dispatch('init', {call: true});
            }).catch(() => {
                this.$store.dispatch('init', {call});
            })
            this.$store.dispatch('init', {call});
        } else {
            this.$store.dispatch('init', {call});
        }
    }
}
</script>
