import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from "@/views/LoginView";
import SignupView from "@/views/SignupView";
import LogoutView from "@/views/LogoutView";
import RoomsView from "@/views/RoomsView";
import VideoView from "@/views/VideoView";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/login',
        name: 'login',
        component: LoginView
    },
    {
        path: '/signup',
        name: 'signup',
        component: SignupView
    },
    {
        path: '/logout',
        name: 'logout',
        component: LogoutView
    },
    {
        path: '/rooms',
        name: 'rooms',
        component: RoomsView
    },
    {
        path: '/video/:space',
        name: 'video',
        component: VideoView
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
