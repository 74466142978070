<template>
    <nav v-if="!call">
        <router-link to="/">Home</router-link>
        <router-link v-if="!authenticated" to="/login">Login</router-link>
        <router-link v-if="!authenticated" to="/signup">Signup</router-link>
        <router-link v-if="authenticated" to="/rooms">Rooms</router-link>
        <router-link v-if="authenticated" to="/logout">Logout</router-link>
    </nav>
    <nav v-else-if="call">
        <router-link to="/">Home</router-link>
    </nav>
</template>

<script>
export default {
    name: "NavigationLinks",
    computed: {
        authenticated() {
            return this.$store.state.authenticated
        },
        call() {
            return this.$store.state.call
        }
    }
}
</script>

<style scoped>

nav {
    padding: 30px;
}

nav a {
    font-weight: bold;
    color: #2c3e50;
    margin-left: 1em;
    margin-right: 1em;
}

nav a.router-link-exact-active {
    color: #42b983;
}
</style>
