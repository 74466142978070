<template>
    <form class="signup" @submit.prevent="submit">
        <table>
            <tr>
                <td>
                    <label for="username">Username</label>
                </td>
                <td class="input">
                    <input name="username" ref="username">
                </td>
            </tr>
            <tr>
                <td>
                    <label for="password">Password</label>
                </td>
                <td class="input">
                    <input name="password" type="password" ref="password">
                </td>
            </tr>
            <tr>
                <td></td>
                <td class="submit">
                    <input type="submit">
                </td>
            </tr>
            <tr>
                <td></td>
                <td class="error input">
                    {{ error }}
                </td>
            </tr>
        </table>
    </form>
</template>

<script>

import gql from "graphql-tag";

export default {
    name: 'SignupView',
    components: {},
    data() {
        return {
            error: ""
        }
    },
    mounted() {
        this.$refs.username.focus()
    },
    methods: {
        submit() {
            this.$apollo.mutate({
                mutation: gql`
                mutation($username: String!, $password: String!) {
                  user {
                    signup(username: $username, password: $password) {
                      access_token
                      refresh_token
                      user {
                        id
                      }
                    }
                  }
                }`,
                variables: {
                    username: this.$refs.username.value,
                    password: this.$refs.password.value
                }
            }).then(({data: {user: {signup: {access_token, refresh_token, user: {id}}}}}) => {
                this.$store.dispatch('login', {access_token, refresh_token, user_id: id});
                this.$router.push('/rooms')
            }).catch((error) => {
                this.error = error
            })
        }
    }
}
</script>

<style scoped>
.signup {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.signup td {
    text-align: right;
}

.signup .input input {
    width: 20em;
}

.signup .error {
    text-align: center;
    color: red;
    max-width: 15em;
}
</style>
