<template>
    <div class="video" v-if="pc" key="rtc">
        <span key="spaceid">video {{ $route.params.space }}</span>
        <div class="chat" key="chat">
            <input v-model="nickname" @keyup.enter="nickupdate"/>
            <div class="messages" ref="messages">
                <div class="message" v-for="message in messages" v-bind:key="message.id">
                    <span class="date">[{{
                        new Date(message.created).getHours()
                        }}:{{ new Date(message.created).getMinutes() }}]</span>
                    <span class="user">&lt;{{
                            memberdata[message.member] && memberdata[message.member].nickname ? memberdata[message.member].nickname : (message.user && message.user.username ? message.user.username : 'Anonymous')
                        }}&gt;</span>
                    <span class="text">{{ message.text }}</span>
                </div>
            </div>
            <div class="input">
                <input v-model="chatinput" @keyup.enter="chatsend"/>
                <button @click="chatsend">Send</button>
            </div>
        </div>
        <div class="self" key="self">
            <video ref="self" width="160" height="120" autoplay muted></video>
        </div>
        <div key="buttons">
            <button @click="togglemic">{{ mic ? (mic.enabled ? 'Mute' : 'Unmute') : 'Enable' }} mic</button>
            <button @click="togglevid">{{ vid ? 'Disable' : 'Enable' }} vid</button>
        </div>
        <div class="members" key="members">
            <div v-for="member in otherMembers" class="peer" v-bind:key="member.id" ref="members" :id="member.id">
                <video width="320" height="240" autoplay controls></video>
            </div>
        </div>
    </div>
    <div v-else-if="init" key="join">
        <button @click="initRtc" key="joinbutton">Join</button>
    </div>
    <div v-else key="waiting">
        <span key="text">Connecting...</span>
    </div>
</template>

<style scoped>
.video {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.chat {
    height: 300px;
    width: 30em;
    margin: 1em;
    position: relative;
    display: flex;
    flex-direction: column;
}

.messages {
    flex-grow: 1;
}

.input {
    height: 2em;
    width: 100%;
    display: flex;
    flex-direction: row;
}

.input * {
    height: 100%;
    margin: 0;
    padding: 0;
    border: 0;
}

.input input {
    flex-grow: 1;
    height: 1.5em;
    padding: 0.5em;
}

.input button {
    width: 5em;
    background: #eee;
    color: #000;
}

.input button:hover {
    background: #ccc;
    cursor: pointer;
}

.messages {
    display: flex;
    flex-direction: column;
    align-items: start;
    text-align: left;
    overflow-y: auto;
}

.message > span {
    margin-right: 0.5em;
    margin-left: 0.5em;
}

.user {
    width: 10em;
    display: inline-block;
    text-align: right;
}
</style>
<script>

import gql from "graphql-tag";

export default {
    name: 'VideoView',
    components: {},
    data() {
        return {
            selfID: null,
            init: false,
            memberids: [],
            members: {},
            memberdata: {},
            rtcpeers: {},
            pc: null,
            messages: [],
            chatinput: '',
            nickname: this.$store.state.nickname || 'Anonymous',
            mic: null,
            vid: null,
            vidSender: null,
            negotiating: false
        }
    },
    updated() {
        this.$nextTick(() => this.scrollToEnd());
    },
    watch: {
        '$store.state.nickname': function () {
            this.nickname = this.$store.state.nickname
        }
    },
    unmounted() {
        console.log("unmount");
    },
    deactivated() {
        console.log("deactivated");
        if (this.pc) {
            console.log("close rtc");
            this.pc.close();
        }
        this.pc = null;
        this.selfID = null;
        this.init = false;
    },
    mounted() {
        const observer = this.$apollo.subscribe({
            query: gql`
            subscription($space: ID!) {
              meet(space: $space) {
                self {
                  init
                  membership {
                    id
                    nickname
                    permissions
                  }
                }
                rtc {
                  ice {
                    candidates
                  }
                  sdp {
                    answer
                    offer
                  }
                  member {
                    add {
                      id
                      media {
                        audio
                        video
                      }
                      reaction {
                        emotion
                        gesture
                      }
                    }
                    data {
                      id
                      media {
                        audio
                        video
                      }
                      reaction {
                        emotion
                        gesture
                      }
                    }
                    change {
                      id
                      media {
                        audio
                        video
                      }
                      reaction {
                        emotion
                        gesture
                      }
                    }
                    remove
                  }
                }
                member {
                  add {
                    members {
                      id
                      nickname
                      permissions
                      presence_state
                    }
                  }
                  data {
                    members {
                      id
                      nickname
                      permissions
                      presence_state
                    }
                  }
                  remove {
                    members {
                      id
                      source {
                        ban { reason }
                        kick { reason }
                        conflict { device_id, user_id }
                        disconnect
                      }
                    }
                  }
                  change {
                    members {
                      id
                      nickname
                      permissions
                      presence_state
                    }
                  }
                }
                chat {
                  sent {
                    messages {
                      id
                      created
                      updated
                      member
                      reactions {
                        name
                        count
                        me
                      }
                      user {
                        username
                      }
                      text
                    }
                  }
                  edit {
                    messages {
                      id
                      updated
                      text
                    }
                  }
                  remove {
                    messages
                  }
                  reaction {
                    count {
                      messages {
                        id
                        channel
                        space
                        reactions {
                          name
                          count
                        }
                      }
                    }
                    self {
                      messages {
                        id
                        channel
                        space
                        reactions
                      }
                    }
                  }
                }
              }
            }`,
            variables: {
                space: this.$route.params.space
            }
        })

        const comp = this

        observer.subscribe({
            next({data: {meet: {self, rtc, member, chat}}}) {
                if (self != null && self.membership != null) {
                    comp.initMembership(self);
                } else if (self != null && self.init) {
                    console.log('init', self);
                    comp.initOk();
                } else if (rtc != null && rtc.ice != null) {
                    console.log('ice', rtc.ice)
                    comp.ice(rtc.ice)
                } else if (rtc != null && rtc.sdp != null) {
                    console.log('sdp', rtc.sdp)
                    comp.sdp(rtc.sdp)
                }  else if (rtc != null && rtc.member != null) {
                    if (rtc.member.add != null) {
                        console.log('join', rtc.member.add)
                        comp.join(rtc.member.add)
                    } else if (rtc.member.remove != null) {
                        console.log('remove', rtc.member.remove)
                        comp.leave(rtc.member.remove)
                    } else if (rtc.member.change != null) {
                        console.log('change', rtc.member.change)
                    } else if (rtc.member.data != null) {
                        console.log('member init', rtc.member.data)
                        comp.join(rtc.member.data)
                    }
                } else if (member != null) {
                    if (member.add != null) {
                        member.add.members.forEach(m => comp.memberdata[m.id] = m)
                    } else if (member.change != null) {
                        member.change.members.forEach(m => comp.memberdata[m.id] = m)
                    } else if (member.data != null) {
                        member.data.members.forEach(m => comp.memberdata[m.id] = m)
                    }
                } else if (chat != null) {
                    if (chat.sent != null) {
                        comp.addMessages(chat.sent.messages);
                    } else if (chat.edit != null) {
                        comp.replaceMessages(chat.edit.messages);
                    } else if (chat.remove != null) {
                        comp.removeMessages(chat.remove.messages);
                    }
                }

            },
            error(error) {
                console.error(error)
            }
        })
    },
    computed: {
        otherMembers() {
            return this.memberids.filter(x => x !== this.selfID).map(x => this.members[x])
        }
    },
    methods: {
        togglemic() {
            if (this.mic) {
                this.mic.enabled = !this.mic.enabled;
                this.$forceUpdate();
            } else {
                navigator.mediaDevices.getUserMedia({video: false, audio: true}).then(stream => {
                    stream.getTracks().forEach(track => {
                        this.mic = track;
                        this.pc.addTrack(track, stream);
                    });
                }).catch(console.error)
            }
        },
        togglevid() {
            if (this.vid) {
                this.vid.stop();
                this.vid = null;
            } else {
                navigator.mediaDevices.getUserMedia({video: true, audio: false}).then(stream => {
                    stream.getTracks().forEach(track => {
                        this.vid = track;
                        if (this.vidSender != null) {
                            this.vidSender.replaceTrack(track);
                        } else {
                            this.vidSender = this.pc.addTrack(track, stream);
                        }
                    });
                    this.$refs.self.srcObject = stream
                }).catch(console.error)
            }
        },
        scrollToEnd() {
            if (!this.pc) {
                return;
            }
            var content = this.$refs.messages;
            content.scrollTop = content.scrollHeight
        },
        nickupdate() {
            this.$apollo.mutate({
                mutation: gql`
                mutation($nick: String!) {
                  shard {
                    update(nickname: $nick) {
                      id,
                      nickname
                    }
                  }
                }`,
                variables: {
                    nick: this.nickname
                }
            })
            this.$store.dispatch('nickname', {nickname: this.nickname});
        },
        addMessages(messages) {
            if (this.messages == null) {
                this.messages = [];
            }
            if (this.messages.length === 0 || this.messages[this.messages.length - 1].id < messages[0]) {
                this.messages.push(...messages);
            }
            console.log('chat add', messages)
        },
        replaceMessages(messages) {
            if (this.messages == null) {
                this.messages = [];
            }
            this.messages.map(msg => {
                const ex = messages.find(x => x.id === msg.id);
                if (ex != null) {
                    ex.text = msg.text;
                    ex.embeds = msg.embeds;
                    ex.updated = msg.updated;
                }
            })
            console.log('chat edit', messages, this.messages)
        },
        removeMessages(messages) {
            if (this.messages == null) {
                this.messages = [];
            }
            this.messages = this.messages.filter(msg => messages.find(x => x === msg.id) == null);
            console.log('chat remove', messages, this.messages)
        },
        chatsend() {
            if (this.chatinput === '') {
                return
            }
            this.$apollo.mutate({
                mutation: gql`
                mutation($text: String!) {
                  meet {
                    chat {
                      send(text: $text)
                    }
                  }
                }`,
                variables: {
                    text: this.chatinput
                }
            })
            this.chatinput = '';
        },
        initOk() {
            console.log("init ok")
            this.init = true
            let historyCursor = "";

            const messageHistory = () =>
                this.$apollo.mutate({
                    mutation: gql`
                    query($cursor: String, $limit: Int!) {
                      meet {
                        chat {
                          history(cursor: $cursor, limit: $limit) {
                            data {
                              id
                              created
                              updated
                              member
                              reactions {
                                name
                                count
                                me
                              }
                              user {
                                username
                              }
                              text
                            }
                            cursor
                          }
                        }
                      }
                    }`,
                    variables: {
                        limit: 100,
                        cursor: historyCursor
                    }
                }).then(({data: {meet: {chat: {history: {cursor, data}}}}}) => {
                    historyCursor = cursor
                    this.addMessages(data)
                    if (cursor != null) {
                        messageHistory()
                    }
                });

            messageHistory()
        },
        initMembership({membership}) {
            console.log('self id', membership[0].id)
            this.selfID = membership[0].id
            this.members = {}
            this.memberids = []
        },
        initRtc() {
            this.scrollToEnd();
            this.pc = new RTCPeerConnection({
                iceTransportPolicy: 'relay',
                iceServers: [
                    {
                        "urls": "stun:ice.sign.dcloud.tech"
                    },
                    {
                        "urls": ["turns:ice.sign.dcloud.tech:443", "turns:ice.sign.dcloud.tech"],
                        "username": "frontend",
                        "credential": "frontend"
                    }
                ]
            });
            this.pc.oniceconnectionstatechange = () => console.log(this.pc.iceConnectionState)
            this.pc.onicecandidate = event => {
                if (event.candidate !== null && event.candidate.candidate !== "") {
                    this.$apollo.mutate({
                        mutation: gql`
                        mutation($candidate: String!) {
                          meet {
                            rtc {
                              ice(candidates: [$candidate])
                            }
                          }
                        }`,
                        variables: {
                            candidate: btoa(JSON.stringify(event.candidate))
                        }
                    })
                }
            }

            this.pc.addTransceiver('video');
            this.pc.addTransceiver('audio');

            const rtcpeers = this.rtcpeers;

            this.pc.ontrack = function (event) {
                console.log('ontrack', event.streams[0].id)

                let id = event.streams[0].id;
                rtcpeers[id] = event.streams[0];
                const video = document.getElementById(id).querySelector('video');
                video.srcObject = event.streams[0];
                video.play();

                event.streams[0].onremovetrack = () => {
                    if (!event.streams[0].getTracks().length) {
                        video.srcObject = null;
                    }

                    video.load();
                }
            }

            this.pc.onnegotiationneeded = async () => {
                console.log('onnego', this.negotiating)

                try {
                    this.negotiating = true;
                    await this.pc.setLocalDescription();
                    this.$apollo.mutate({
                        mutation: gql`
                        mutation($offer: String!) {
                          meet {
                            rtc {
                              sdp(offer: $offer)
                            }
                          }
                        }`,
                        variables: {
                            offer: btoa(JSON.stringify(this.pc.localDescription))
                        }
                    });
                } catch (err) {
                    console.error(err);
                } finally {
                    this.negotiating = false;
                }
            }

            this.pc.onsignalingstatechange = () => {
                console.log('signaling', this.pc.signalingState)
                if (this.pc.iceConnectionState === "failed") {
                    this.pc.restartIce();
                }
            }
        },
        ice({candidates}) {
            candidates.forEach(candidate => {
                console.log('candidate', JSON.parse(atob(candidate)));
                this.pc.addIceCandidate(JSON.parse(atob(candidate)));
            })
        },
        sdp({answer, offer}) {
            if (answer) {
                this.pc.setRemoteDescription(new RTCSessionDescription(JSON.parse(atob(answer))));
            } else if (offer) {
                if (this.negotiating || this.pc.signalingState !== "stable") {
                    return;
                }

                this.pc.setRemoteDescription(new RTCSessionDescription(JSON.parse(atob(offer))));

                this.pc.setLocalDescription().then(() => {
                    this.$apollo.mutate({
                        mutation: gql`
                        mutation($answer: String!) {
                          meet {
                            rtc {
                              sdp(answer: $answer)
                            }
                          }
                        }`,
                        variables: {
                            answer: btoa(JSON.stringify(this.pc.localDescription))
                        }
                    })
                }).catch(console.error);
            }
        },
        join(members) {
            members.forEach(x => {
                if (!(x.id in this.members)) {
                    this.members[x.id] = x
                    this.memberids.push(x.id)
                }
            })
        },
        leave(members) {
            members.forEach(x => {
                this.memberids = this.memberids.filter(p => p !== x)
                delete this.members[x]
                delete this.rtcpeers[x]
            })
        }
    }
}
</script>
