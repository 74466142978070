<template>
    <form class="logout">
    </form>
</template>

<script>
import gql from 'graphql-tag'

export default {
    name: 'LogoutView',
    components: {},
    mounted() {
        this.$apollo.mutate({
            mutation: gql`
            mutation {
              user {
                logout {
                  access_token
                }
              }
            }`,
            variables: {}
        }).then(() => {
            this.$store.dispatch('login', {access_token: "", refresh_token: "", user_id: ""});
            this.$router.push('/')
        }).catch((error) => {
            console.error(error)
        })
    }
}
</script>

<style scoped>

</style>
