<template>
    <div class="spaces">
        <button @click="add">Add</button>
        <div v-for="space of spaces" v-bind:key="space.id">
            <div>
                <a :href="'/video/' + space.id" target="_blank">{{ space.id }}</a>
                <button @click="remove(space.id)">x</button>
            </div>
        </div>
    </div>
</template>

<script>

import gql from "graphql-tag";

export default {
    name: 'RoomsView',
    components: {},
    data() {
        return {
            spaces: []
        }
    },
    computed: {
        authenticated() {
            return this.$store.state.authenticated
        }
    },
    watch: {
        '$store.state.access_token': function () {
            this.load()
        }
    },
    mounted() {
        this.load()
    },
    methods: {
        add() {
            this.$apollo.mutate({
                mutation: gql`
                mutation {
                  meet {
                    space {
                      save {
                        id
                        owner {
                          id
                        }
                      }
                    }
                  }
                }`,
                variables: {}
            }).then(({data: {meet: {space: {save}}}}) => {
                this.spaces.push(save)
            }).catch((error) => {
                console.error(error)
            })
        },
        remove(id) {
            this.$apollo.mutate({
                mutation: gql`
                mutation ($id: ID!) {
                  meet {
                    space {
                      remove(id: $id)
                    }
                  }
                }`,
                variables: {
                    id
                }
            }).then(() => {
                this.spaces = this.spaces.filter(x => x.id !== id)
            })
        },
        load() {
            if (!this.$store.state.authenticated || !this.$store.state.init) {
                return
            }

            this.$apollo.mutate({
                mutation: gql`
                query {
                  user {
                    meet {
                      list {
                        spaces {
                          joined
                          space {
                            id
                            owner {
                              id
                            }
                          }
                        }
                      }
                    }
                  }
                }`,
            }).then(({data: {user: {meet: {list: {spaces}}}}}) => {
                this.spaces = spaces.map(x => x.space)
            }).catch((error) => {
                console.error(error)
            })
        }
    }
}
</script>
